import React, { createContext } from 'react';

import { CartProvider } from '../../context/CartContext';

import Layout from '../../layouts/en.jsx';
import { ShoppingCartSlideoutEn } from '@components/pages/shop';
import { CartContext } from '../../context/CartContext';

export const UserStateContext = createContext(null);

const LayoutStore = ({ children, pageId }) => {
  return (
    <CartProvider>
      <CartContext.Consumer>
        {({ showShoppingCartEn, setShowShoppingCartEn }) => (
          <>
            <Layout pageId={pageId}>{children}</Layout>
            {showShoppingCartEn && (
              <ShoppingCartSlideoutEn setShowShoppingCartEn={setShowShoppingCartEn} />
            )}
          </>
        )}
      </CartContext.Consumer>
    </CartProvider>
  );
};

export default LayoutStore;
